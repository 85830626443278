<template>
	<div>

		<h1 class="text-2xl font-bold mb-5">All Settlements</h1>

		<div class="border border-solid border-blue-200 rounded overflow-hidden">
			<datatable :data="settlements" :columns="columns" :loading="loading" :query="searchQuery" ref="table" />
		</div>

	</div>
</template>
<script>
	import moment from 'moment';

	export default {
		data() {
			return {
				searchQuery: '',
				company: null,
				loading: false,
				settlements: [],
				columns: [
					{
						name: 'company',
						th: 'Company',
						render: (settlement) => settlement.company.name
					},
					{
						name: 'name',
						th: 'Loan Batch',
					},
					{
						name: 'status',
						th: 'Status',
						render: (settlement) => {
							switch (settlement.status) {
								case 'paid':
									return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Paid
									</div>`;
								case 'transferred':
									return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Transferred
									</div>`;
								case 'not_due':
									return `<div class="rounded-sm border border-blue-300 text-blue-300 bg-blue-100 text-xs px-3 py-2 inline-block">
										Not Due
									</div>`;
								default:
									if (settlement.disputed_at) {
										return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
											Disputed
										</div>`;
									}
									if (settlement.defaulted) {
										return `<div class="rounded-sm border border-red-500 text-red-500 bg-red-100 text-xs px-3 py-2 inline-block">
											Default
										</div>`;
									}
									break;
							}
						}
					},
					{
						name: 'amount',
						th: 'Amount',
						render: (settlement, amount) => `₦ ${this.$options.filters.currency(amount)}`
					},
					{
						name: 'created_at',
						th: 'Date',
						render: (settlement, created_at) => moment(created_at).fromNow()
					},
				]
			}
		},
		beforeMount() {
			this.getSettlements();
		},
		methods: {
			async getSettlements() {
				this.loading = true;
				await this.$get({
					url: `${this.$baseurl}/settlements`,
					headers: this.headers,
					success: (response) => {
						this.settlements = response.data.settlements;
					}
				});
				this.loading = false;
			}
		}
	}
</script>