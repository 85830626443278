<template>
  <div class="mb-20">
    <div class="border border-solid border-blue-200">
      <div class="flex">
        <div
          class="duration-300"
          :class="{ 'w-full': !isOpen, 'w-1/2': isOpen }"
        >
          <datatable
            :exportable="false"
            :fillable="true"
            :index="true"
            :pageDetails="true"
            :reverse-index="true"
            :columns="table.columns"
            :data="rewards.data"
            :query="query"
            :loading="rewards.loading"
            :onClick="selectRow"
            :search-field="searchField"
            ref="table"
          />
        </div>
        <div class="w-1/2 px-8 py-10 border-l border-blue-200" v-if="isOpen">
          <div class="h-full overflow-y-auto">
            <div class="animated animation-delay-300 fadeIn">
              <div class="mb-5">
                <div class="flex flex-row items-center justify-between mb-1">
                  <div class="flex flex-row items-center">
                    <h2 class="text-lg font-bold mr-2">
                      {{ selectedAffiliateName }}
                    </h2>
                  </div>
                </div>
                <router-link
                  :to="{
                    name: 'affiliate-view',
                    params: { userId: selectedAffiliateID },
                  }"
                  class="inline-flex items-center text-sm text-blue-500"
                >
                  <span>View Profile</span>
                </router-link>
              </div>
              <div class="text-xm text-gray-500">
                Batch list
              </div>
              <transition name="fade" mode="out-in">
                <div>
                  <datatable
                    :index="true"
                    :data="batchList.data"
                    :columns="batchList.columns"
                    :loading="batchList.loading"
                  />
                </div>
              </transition>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 pt-10"
      ref="transferConfirmationModal"
    >
      <h4 class="text-xl font-bold mb-6">Confirm Transfer</h4>

      <!-- <template v-if="getFormError(withdrawForm)">
        <div class="alert alert-red-soft mb-16">
          <div class="alert-icon">!</div>
          <span>{{ getFormError(withdrawForm) }}</span>
        </div>
      </template> -->

      <div class="mb-6">Are you sure you want to Transfer?</div>

      <div class="bg-blue-200 rounded p-6 text-center mb-4">
        <div class="text-2xl font-bold">
          ₦{{ selectedCommission | currency }}
        </div>
        <!-- <div class="text-2xl font-bold">₦{{ 2000 | currency }}</div> -->
        <div class="text-sm text-gray-600">Amount</div>
      </div>

      <div class="border border-dashed border-blue-200 rounded p-6 mb-4">
        <div class="font-bold mb-4">{{ selectedName }}</div>

        <!-- <div class="grid grid-cols-2 gap-3 mb-4">
          <div class="col-span-1">
            <div class="bg-blue-200 p-4 flex items-center rounded">
              <ion-icon
                name="wallet-outline"
                class="text-blue-500 mr-2"
              ></ion-icon>
              <span class="text-xs">{{ selectedBatch }}</span>
            </div>
          </div>
          <div class="col-span-1">
            <div class="bg-blue-200 p-4 flex items-center rounded">
              <ion-icon
                name="business-outline"
                class="text-blue-500 mr-2"
              ></ion-icon>
              <span class="text-xs">{{ bankName }}</span>
            </div>
          </div>
        </div> -->
      </div>

      <div class="flex flex-row items-center">
        <button
          type="button"
          class="btn btn-gray-soft btn-md mr-2"
          @click.prevent="closeSettleModal"
          :disabled="transferForm.loading"
        >
          Cancel
        </button>
        <button
          type="button"
          class="btn btn-blue btn-md w-full"
          @click.prevent="settleRequest"
          :disabled="transferForm.loading"
        >
          <span v-if="transferForm.loading">Verifying</span>
          <span v-else>Confirm</span>
        </button>
      </div>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="successModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />

      <div class="text-lg font-bold mb-4">
        Success!
      </div>
      <div class="text-sm mb-10">
        Withdrawal request sent successfully.
      </div>

      <button
        type="button"
        class="btn btn-blue"
        @click.prevent="$refs.successModal.close"
      >
        Okay
      </button>
    </modal>

    <modal
      className="w-full md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="errorModal"
    >
      <img
        :src="assets.icons.lg.exclamation"
        class="mx-auto mb-8"
        height="50"
        alt="Check Mark"
      />

      <div class="text-lg font-bold mb-4">
        Error!
      </div>
      <div class="text-sm mb-10">
        There was an issue requesting for your earned balance, please can you
        contact the customer service
      </div>

      <button
        type="button"
        class="btn btn-blue"
        @click.prevent="$refs.errorModal.close"
      >
        Okay
      </button>
    </modal>
  </div>
</template>

<script>
export default {
  props: {
    query: {
      type: String,
      default: "",
    },
    searchField: {
				type: [HTMLInputElement],
				default: null
			}
  },
  data() {
    return {
      url: `${this.$baseurl}/admin/affiliates/rewards/all-batch-request-to-withdraw?status=pending`,
      selectedUser: null,
      users: this.$options.resource([]),
      approvalForm: this.$options.basicForm([
        "credit_limit",
        "loan_limit",
        "interest_rate",
        "billing_date",
        "payment_date",
      ]),
      loading: null,
      profileApprovals: [],
      searchQuery: "",
      selectedUserSummary: null,
      table: this.$options.resource([], {
        columns: [
          {
            name: "batch",
            th: "Batch Number",
          },
          {
            name: "name",
            th: "Affiliate Name",
            render: (reward) => {
              return `${reward.name}`;
            },
          },
          {
            name: "commission",
            th: "Commission Amount",
            render: (reward, commission) => {
              return this.$options.filters.currency(commission || 0);
            },
          },
          {
            name: "phone",
            th: "Phone no",
          },
          {
            name: "email",
            th: "Email",
          },
          {
            name: "pay",
            th: "Action",
            render: (settlement) => `
              <div class="flex flex-row items-center justify-between">
                <button
                  class="btn btn-blue"
                  data-click="openSettleModal(${settlement.affiliate_id}, ${settlement.batch}, ${settlement.commission}, ${settlement.name})"
                >
                  Pay
                </button>
              </div>
            `,
          },
        ],
        selected: null,
      }),
      rewards: this.$options.resource([], {
        loading: false,
      }),
      paymentRequest: this.$options.resource([], {
        loading: false,
      }),
      batchList: this.$options.resource([], {
        loading: false,
        columns: [
          {
            name: "name",
            th: "Referred Name",
            render: (reward) => {
              return `${reward?.converted_owner?.name} ${reward?.converted_owner?.last_name}`;
            },
          },
          {
            name: "commission",
            th: "Commission Amount",
            render: (reward, commission) => {
              return this.$options.filters.currency(commission || 0);
            },
          },
          {
            name: "product",
            th: "Product",
          },
        ],
      }),
      selectedPayment: null,
      selectedSettlement: null,
      transferForm: this.$options.resource([], {
        loading: false,
      }),
    };
  },
  computed: {
    selectedCommission() {
      return this.selectedSettlement?.commission;
    },
    selectedBatch() {
      return this.selectedSettlement?.batch;
    },
    selectedName() {
      return this.selectedSettlement?.name;
    },
    selectedAffiliateName() {
      return this.selectedPayment?.name;
    },
    selectedAffiliateID() {
      return this.selectedPayment?.affiliate_id;
    },
    userFirstName() {
      // return this.selectedUser?.name.split(' ')[0];
      return this.selectedUser?.firstname;
    },
    userProfileActivated() {
      return (
        (!!this.userApprovalCompleted &&
          this.userApproval.action === "activate") ||
        this.selectedUser?.profile?.status === "activated"
      );
    },
    userProfileCompleted() {
      return this.selectedUser?.profile;
    },
    userProfileDeactivated() {
      return (
        (!!this.userApprovalCompleted &&
          this.userApproval.action === "deactivate") ||
        this.selectedUser?.profile?.status === "deactivated"
      );
    },
    userApprovalPending() {
      return this.selectedUser?.profile?.status === "pending";
    },
    userApproval() {
      return this.profileApprovals.find(
        (approval) => approval.id === this.selectedUser.id
      );
    },
    userApprovalCompleted() {
      return this.userApproval?.response?.data?.status;
    },
    userApprovalError() {
      const error = this.userApproval?.error;
      if (!error) {
        return null;
      }

      if (error?.toString().match(/Error: Network Error/i)) {
        return "Please check your internet connection";
      }

      if (error?.response?.data?.message) {
        return error?.response?.data?.message;
      }

      return "Please try again later.";
    },
    userApprovalLoading() {
      return this.userApproval?.loading === true;
    },
    isOpen() {
      return !!this.selectedPayment;
    },
    totalLoanAmount() {
      return (
        this.transactions &&
        this.transactions.reduce(
          (last, transaction) => last + Number(transaction.loan_amount),
          0
        )
      );
    },
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
  beforeMount() {
    this.getRewards();
    // this.pendingRequest();
    this.getRewards();
  },
  methods: {
    selectRow(selectedPayment) {
      this.selectedPayment =
        this.selectedPayment === selectedPayment ? null : selectedPayment;
      this.$refs.table.renderData();
      if (this.selectedPayment) {
        this.getBatchItems();
      }
    },
    openSettleModal(affiliateId, batch, commission, name) {
      this.selectedPayment = null;
      this.selectedSettlement = {
        affiliateId,
        batch,
        commission,
        name,
      };
      this.$refs.transferConfirmationModal.open();
    },
    closeSettleModal() {
      this.$refs.transferConfirmationModal.close();
    },
    tableRowClassName(row) {
      return row.id === this.selectedPayment?.id ? "selected" : "";
    },
    async pendingRequest() {
      this.paymentRequest.loading = true;
      await this.$get({
        url: `${this.$baseurl}/admin/affiliates/rewards/all-batch-request-to-withdraw`,
        headers: this.headers,
        success: (response) => {
          this.paymentRequest.data = Object.values(response.data.data);
        },
        error: (error) => {
          console.log("err", error);
        },
      });

      this.paymentRequest.loading = false;
    },
    async getBatchItems() {
      const { batch, affiliate_id } = this.selectedPayment;
      this.batchList.loading = true;
      await this.$get({
        url: `${this.$baseurl}/admin/affiliates/rewards/one-batch-request-to-withdraw/${affiliate_id}/${batch}`,
        headers: this.headers,
        success: (response) => {
          this.batchList.data = response.data.data.data;
        },
        error: (error) => {
          console.log("err", error);
        },
      });

      this.batchList.loading = false;
    },
    async settleRequest() {
      this.transferForm.loading = true;
      await this.$post({
        url: `${this.$baseurl}/admin/affiliates/rewards/settle-request-to-withdraw`,
        data: {
          affiliateId: this.selectedSettlement?.affiliateId,
          batch: this.selectedSettlement?.batch,
        },
        headers: this.headers,
        success: () => {
          this.pendingRequest();
          this.closeSettleModal();
          this.$refs.successModal.open();
        },
        error: (error) => {
          console.log("err", error);
          this.closeSettleModal();
          this.$refs.errorModal.open();
        },
      });

      this.transferForm.loading = false;
    },
    async getRewards() {
      this.rewards.loading = true;
      await this.sendRequest("admin.affiliate.rewards.pending", {
        success: (response) => {
          const { data } = response.data;
          this.rewards.data = Object.values(data);
        },
        error: (error) => {
          this.rewards.error = error;
        },
      });
      this.rewards.loading = false;
    },
  },
};
</script>