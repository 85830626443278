<template>
  <div class="mb-20">
    <div class="border border-solid border-blue-200 rounded overflow-hidden">
      <datatable
        :url="url"
        :ajax="true"
        :ajaxPagination="true"
        :exportable="true"
        :fillable="true"
        :index="true"
        :pageDetails="true"
        :reverse-index="true"
        :columns="table.columns"
        :query="query"
        :loading="rewards.loading"
        ref="table"
      />
    </div>
  </div>
</template>
<script>
//   import moment from 'moment';

export default {
  props: {
    query: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      url: `${this.$baseurl}/admin/affiliates/rewards?status=disbursed`,
      table: this.$options.resource([], {
        columns: [
          {
            name: "reference",
            th: "Reference Number",
          },
          {
            name: "affiliate_marketer",
            th: "Affiliate Name",
            render: (reward) => {
              return `${reward.affiliate_marketer.first_name} ${reward.affiliate_marketer.last_name}`;
            },
          },
          {
            name: "product",
            th: "Product",
          },
          {
            name: "commission",
            th: "Commission Amount",
            render: (reward) => {
              return this.$options.filters.currency(reward.commission || 0);
            },
          },
          {
            name: "status",
            th: "Status",
            render: (reward, status) => {
              switch (status) {
                case "disbursed":
                  return `<div class="badge badge-green-soft-outline">
                      Paid
                  </div>`;
                case "pending":
                default:
                  return `<div class="badge badge-orange-soft-outline">
                      Pending
                  </div>`;
              }
            },
          },
        ],
        selected: null,
      }),
      rewards: this.$options.resource([], {
        loading: false,
      }),
    };
  },
  beforeMount() {
    this.getRewards();
  },
  mounted() {},
  methods: {
    async getRewards() {
      this.rewards.loading = true;
      await this.sendRequest("admin.affiliate.rewards.disbursed", {
        success: (response) => {
          const { data } = response.data.data;
          this.rewards.data = data;
        },
        error: (error) => {
          this.rewards.error = error;
        },
      });
      this.rewards.loading = false;
    },
  },
};
</script>