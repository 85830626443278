<template>
	<div>

		<h1 class="text-2xl font-bold mb-5">Pending Settlement</h1>

		<sm-loader v-if="loading" class="mb-20" />
		<template v-else-if="nextSettlement">
			<div class="flex flex-wrap item-center mb-10">
				<div class="w-full md:w-8/12 lg:w-full xl:w-8/12 flex flex-wrap border">
					<div class="w-full md:w-1/3 px-12 relative py-8">
						<span class="absolute h-4 bg-blue-500 block left-0 ml-5" style="width: 3px"></span>
						<div class="flex justify-between items-center mb-4">
							<div class="text-xs">Loan Batch</div>
						</div>
						<sm-loader v-if="false" />
						<div class="text-lg" v-else>{{ nextSettlement.name }}</div>
					</div>
					<div class="w-full md:w-1/3 px-12 relative py-8 border-t md:border-l md:border-t-0 border-blue-200">
						<span class="absolute h-4 bg-green-500 block left-0 ml-5" style="width: 3px"></span>
						<div class="flex justify-between items-center mb-4">
							<div class="text-xs">Amount Due</div>
						</div>
						<sm-loader v-if="false" />
						<div class="text-lg" v-else>₦ {{ nextSettlement.amount | currency }}</div>
					</div>
					<div class="w-full md:w-1/3 px-12 relative py-8 border-t md:border-l md:border-t-0 border-blue-200">
						<span class="absolute h-4 bg-orange-500 block left-0 ml-5" style="width: 3px"></span>
						<div class="flex justify-between items-center mb-4">
							<div class="text-xs">Due Date</div>
						</div>
						<sm-loader v-if="false" />
						<div class="text-lg" v-else>{{ nextSettlement.due_date | dateFormat('MM dd, Y') }}</div>
					</div>
				</div>
				<div class="w-full flex flex-row flex-wrap pt-4 md:w-4/12 md:flex-col md:justify-around md:items-start md:pt-0 md:pl-4 lg:w-full lg:flex-row lg:justify-start lg:pt-4 lg:pl-0 xl:w-4/12 xl:items-center xl:justify-center xl:pt-0 xl:pl-4">
					<button class="button bg-blue-500 text-white mr-4 flex items-center pl-8" @click.prevent="showPaymentModal">
						<ion-icon name="wallet-outline" class="mr-2" />
						<span>Pay Now</span>
					</button>
					<button class="button border border-blue-500 text-blue-500">
						View Details
					</button>
				</div>
			</div>
		</template>
		<template v-else>
			<div class="pt-6 pb-32 text-sm opacity-50">
				<p>There are no pending payments here at the moment.</p>
			</div>
		</template>

		<h1 class="text-2xl font-bold mb-5">Upcoming settlements</h1>

		<div class="border border-solid border-blue-200 rounded overflow-hidden">
			<datatable :data="settlements" :columns="columns" :loading="loading" :query="searchQuery" ref="table" />
		</div>

		<modal className="w-full md:w-2/5 lg:w-5/12 xl:w-3/10 pt-12 px-12" ref="paymentModal">

			<h3 class="text-xl font-bold mb-16">Make Payment</h3>

			<form @submit.prevent="makePayment">

				<template v-if="errorMessage">
					<div class="rounded-full bg-red-100 p-3 flex flex-row items-center -mt-10 mb-10">
						<div class="flex justify-center items-center bg-red-500 text-white rounded-full w-20px h-20px text-xs text-center mr-3">
							!
						</div>
						<div class="text-xs font-normal">{{ errorMessage }}</div>
					</div>
				</template>
				
				<form-group
					:left-icon="false"
					v-model="amountDueInCurrency"
					:disabled="true"
				>
					Amount Due
				</form-group>

				<div class="flex flex-row">

					<div class="w-3/10">
						<form-group
							type="text"
							:options="paymentOptions"
							:left-icon="false"
							name="percentage"
							:form="form"
							v-model="form.data.percentage.value"
							disabled
						>
							To Pay
						</form-group>
					</div>
					<div class="w-7/10 pl-4">
						<form-group
							:left-icon="false"
							v-model="amountToPayInCurrency"
							:disabled="true"
						/>
					</div>

					
				</div>

					<form-group
						type="select"
						:options="paymentMethods"
						:left-icon="false"
						name="payment_method"
						:form="form"
						v-model="form.data.payment_method.value"
					>
						Payment Method
					</form-group>
					
				<div class="text-center -mt-8">
					<button type="submit" class="button bg-blue-500 text-white hover:bg-blue-600" :disabled="form.loading">
						<template v-if="form.loading">Verifying...</template>
						<template v-else>Make Payment</template>
					</button>
				</div>

			</form>


		</modal>

		<modal className="w-full md:w-2/5 lg:w-5/12 xl:w-3/10 pt-12 px-12" ref="bankTransferModal">

			<h3 class="text-xl font-bold mb-16">Payment Information</h3>

			<form @submit.prevent="makeBankTransferRequest">

				<template v-if="errorMessage">
					<div class="rounded-full bg-red-100 p-3 flex flex-row items-center -mt-10 mb-10">
						<div class="flex justify-center items-center bg-red-500 text-white rounded-full w-20px h-20px text-xs text-center mr-3">
							!
						</div>
						<div class="text-xs font-normal">{{ errorMessage }}</div>
					</div>
				</template>
				
				<div class="grid grid-cols-2 gap-8">
					<div class="col-span-1">
						<form-group
							:left-icon="false"
							right-icon="copy-outline"
							:right-icon-click="() => copyText(amountToPay)"
							:disabled="true"
							v-model="amountToPayInCurrency"
						>
							To Pay
						</form-group>
					</div>
					<div class="col-span-1">
						<form-group
							:left-icon="false"
							right-icon="copy-outline"
							:right-icon-click="() => copyText(accountNumber)"
							:disabled="true"
							v-model="accountNumber"
						>
							Account Number
						</form-group>
					</div>
				</div>

				<div class="grid grid-cols-2 gap-8">

					<div class="col-span-1">
						<form-group
							:left-icon="false"
							:form="form"
							right-icon="copy-outline"
							:right-icon-click="() => copyText(accountNumber)"
							:disabled="true"
							v-model="accountName"
						>
							Account Name
						</form-group>
					</div>
					<div class="col-span-1">
						<form-group
							:left-icon="false"
							right-icon="copy-outline"
							:right-icon-click="() => copyText(bankName)"
							:disabled="true"
							v-model="bankName"
						>
							Bank Name
						</form-group>
					</div>

					
				</div>

					<form-group
						:left-icon="false"
						right-icon="copy-outline"
						:right-icon-click="() => copyText(paymentReference)"
						:disabled="true"
						v-model="paymentReference"
					>
						Payment Reference
					</form-group>
					
				<div class="grid grid-cols-2 gap-2 -mt-8">
					<button type="submit" class="button w-full px-5 bg-blue-500 text-white hover:bg-blue-600" :disabled="form.loading">
						<template v-if="form.loading">Verifying...</template>
						<template v-else>Mark as Paid</template>
					</button>

					<button
						type="button"
						class="button w-full px-5 border border-blue-500 text-blue-500 hover:bg-blue-600 hover:text-white"
						:disabled="form.loading"
					>
						<template v-if="form.loading">Verifying...</template>
						<template v-else>Contact Support</template>
					</button>
				</div>

			</form>


		</modal>

		<modal className="w-full md:w-2/5 lg:w-5/12 xl:w-3/10 pt-24 px-24 text-center" ref="paymentSuccessModal">

			<img :src="checkmark" class="mx-auto mb-8" height="90" alt="Check Mark">

			<div class="text-lg font-bold mb-4">
				Marked as Paid!
			</div>
			<div class="text-sm font-thin mb-20">
				Please wait while our support team approves your payment.
			</div>

			<button
				type="button"
				class="button border border-blue-500 hover:bg-blue-600 text-blue-500 hover:text-white px-16"
				@click.prevent="$refs.paymentSuccessModal.close"
			>
				Close
			</button>
		</modal>

	</div>
</template>
<script>
	import moment from 'moment';

	export default {
		data() {
			return {
				checkmark: require('@/assets/checkmark-base.svg'),
				searchQuery: '',
				company: null,
				loading: false,
				settlements: [],
				paymentOptions: [
					// {title: '25%', value: 25},
					// {title: '50%', value: 50},
					// {title: '75%', value: 75},
					{title: '100%', value: 100}
				],
				paymentMethods: [
					{ title: 'Card Payment via Paystack', value: 'paystack' },
					// { title: 'Card Payment via Flutterwave', value: 'rave' },
					{ title: 'Bank Transfer', value: 'bank_transfer' },
				],
				columns: [
					{
						name: 'name',
						th: 'Loan Batch',
					},
					{
						name: 'status',
						th: 'Status',
						render: (settlement) => {
							switch (settlement.status) {
								case 'paid':
									return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Paid
									</div>`;
								case 'transferred':
									return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-3 py-2 inline-block">
										Transferred
									</div>`;
								case 'not_due':
									return `<div class="rounded-sm border border-blue-300 text-blue-300 bg-blue-100 text-xs px-3 py-2 inline-block">
										Not Due
									</div>`;
								case 'pending':
									return `<div class="rounded-sm border border-blue-300 text-blue-300 bg-blue-100 text-xs px-3 py-2 inline-block">
										Pending
									</div>`;
								default:
									if (settlement.disputed_at) {
										return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-3 py-2 inline-block">
											Disputed
										</div>`;
									}
									if (settlement.defaulted) {
										return `<div class="rounded-sm border border-red-500 text-red-500 bg-red-100 text-xs px-3 py-2 inline-block">
											Default
										</div>`;
									}
									break;
							}
						}
					},
					{
						name: 'amount',
						th: 'Amount',
						render: (settlement, amount) => `₦ ${this.$options.filters.currency(amount)}`
					},
					{
						name: 'created_at',
						th: 'Date',
						render: (settlement, created_at) => moment(created_at).fromNow()
					},
				],
				form: this.$options.basicForm([
					{name: 'percentage', value: '100'},
					{name: 'payment_method', value: 'paystack'},
				]),
			}
		},
		computed: {
			accountName() {
				return 'Crednet Technologies';
			},
			accountNumber() {
				return '0815690344';
			},
			amountDue() {
				return this.nextSettlement?.amount;
			},
			amountDueInCurrency() {
				return `₦ ${this.$options.filters.currency(this.amountDue)}`;
			},
			amountToPay() {
				return this.amountDue * (this.form.data.percentage.value / 100);
			},
			amountToPayInCurrency() {
				return `₦ ${this.$options.filters.currency(this.amountToPay)}`;
			},
			bankName() {
				return 'Access Bank';
			},
			errorMessage() {
				if (this.form.error.toString().match(/Error: Network Error/i)) {
					return 'Please check your internet connection';
				}

				if (this.form.error?.response?.data?.message) {
					return this.form.error.response.data.message;
				}

				const errors = this.form.error?.response?.data?.error;
				if (errors && Object.keys(errors).length) {
					return 'Please check the form for incorrect or missing data';
				}

				return null;
			},
			nextSettlement() {
				return this.settlements[0];
			},
			paymentReference() {
				const date = new Date();
				const month = date.format('MM');
				const year = date.format('Y');

				return `${this.user?.name} — ${month} ${year} Batch, ${this.form.data.percentage.value}% Payment`;
			}
		},
		beforeMount() {
			this.getSettlements();
		},
		mounted() {
			
		},
		methods: {
			async getSettlements() {
				this.loading = true;
				await this.$get({
					url: `${this.$baseurl}/settlements/due`,
					headers: this.headers,
					success: (response) => {
						this.settlements = response.data.settlements;
					}
				});
				this.loading = false;
			},
			makeBankTransferRequest() {
				this.$refs.bankTransferModal.close();
				this.$refs.paymentSuccessModal.open();
			},
			makePayment() {
				switch (this.form.data.payment_method.value) {
					case 'paystack':
					case 'rave':
					break;
					case 'bank_transfer':
						this.showBankTransferModal();
					break;
				}
			},
			showPaymentModal() {
				this.$refs.paymentModal.open();
			},
			showBankTransferModal() {
				this.$refs.paymentModal.close();
				this.$refs.bankTransferModal.open();
			},
		}
	}
</script>